import React from 'react'
import LayoutBase from '../layouts/LayoutBase'
import { StaticImage } from 'gatsby-plugin-image'
import '../css/nosotros.css';

const Nosotros = () => {
    return (
        <LayoutBase>

            <section className="container-fluid p-0">
                    <StaticImage
                        src="../images/slider/09.jpg"
                        className="d-block w-100 min-h-300"
                        alt="Servicios"
                        layout="fullWidth"
                    />
            </section>

            <div className="container-fluid bg-secondary text-white py-5">
                <header className="container d-flex flex-column">
                    <h1>Nosotros</h1>
                    <p class="px-4 texto">Hoy somos un despacho con capacidad para seis terapeutas y un espacio polivalente para charlas, grupos y formaciones pero nuestros orígenes son sencillos. Todo empezó en 2012 cuando tomé la decisión de abrir un gabinete especializado en TEA, Discapacidad Intelectual y Trastornos del Lenguaje. Desde entonces el equipo se ha ido ampliando así como nuestra respuesta terapéutica abarcando hoy en día todo el ciclo vital. </p>
                    <button class="btn btn-primary text-primary bg-white align-self-center px-4">Leer más</button>
                </header>
            </div>

            <main>
                <section className="container">
                <article className="psicologo row pt-5 px-5">
                        <div className="col-12 col-md-3 d-flex d-md-block justify-content-center">
                            <StaticImage
                                className="psicologo_imagen mb-5 mb-md-0"
                                src="../../assets/avatar/01.png"
                            />
                        </div>
                        <div className="col-12 col-md-9 d-flex-column justify-align-center">
                            <h2 className="psicologo_titulo">Rosa Montcerdá</h2>
                            <h3 className="psicologo_subtitulo">Directora del centro</h3>
                            <h5 className="psicologo_colegiado">Psicóloga General Sanitaria. Nº col: B-1899</h5>
                            <p className="psicologo_txt">Licenciada en Psicología con itinerario clínico por la Universidad de Salamanca cursé un año becada en la Roskilde Universitet, Dinamarca. Mi práctica profesional me lleva a especializarme en técnicas de intervención en autismo e instrumentos de evaluación ADOS y ADI-R (acreditaciones clínicas), discapacidad intelectual y alteraciones del lenguaje. Tras la realización del Máster de Logopedia Clínica y Escolar decido profundizar mis conocimientos en este área volviendo a la universaidad para cursar el que será mi segundo grado, Logopedia.
                            </p>
                        </div>
                    </article>

                    <article className="psicologo row pt-5 px-5">
                        <div className="col-12 col-md-3 d-flex d-md-block justify-content-center">
                            <StaticImage
                                className="psicologo_imagen mb-5 mb-md-0"
                                src="../../assets/avatar/02.png"
                            />
                        </div>
                        <div className="col-12 col-md-9 d-flex-column justify-align-center">
                            <h2 className="psicologo_titulo">Jordi Callizo</h2>
                            <h3 className="psicologo_subtitulo">Psicólogo</h3>
                            <h5 className="psicologo_colegiado">Psicóloga General Sanitaria. Nº col: B-1899</h5>
                            <p className="psicologo_txt">Grado en Psicología con itinerario XXXX por la Universitat de Lleida. Desarrollo mi actividad profesional en Cataluña durante siete años siempre relacionado con TEA, cinco de ellos en la Fundación Loovas, Barcelona. A raíz de esta experiencia profesional realizo un Máster en ABA “Applied Behavior Analisys”. De forma paralela participo en varios estudios de investigación sobre educación hasta que en 2019 me traslado a Mallorca para formar parte del equipo del Centro Desarrollo y Lenguaje.
                            </p>
                        </div>
                    </article>

                    <article className="psicologo row pt-5 px-5">
                        <div className="col-12 col-md-3 d-flex d-md-block justify-content-center">
                            <StaticImage
                                className="psicologo_imagen"
                                src="../../assets/avatar/03.png"
                            />
                        </div>
                        <div className="col-12 col-md-9 d-flex-column justify-align-center">
                            <h2 className="psicologo_titulo">Elisa Toledo</h2>
                            <h3 className="psicologo_subtitulo">Directora del centro</h3>
                            <h5 className="psicologo_colegiado">Psicóloga General Sanitaria. Nº col: B-1899</h5>
                            <p className="psicologo_txt">Grado en Psicología por la Universitat de les Illes Balears con especialización en Psicología Infantil. Complemento mis estudios con el Máster en Actualización e Intervención Psicológica y Salud Mental por la UDIMA, Máster en Psicología General Sanitaria por la VIU y Máster en Neuropsicología Clínica por la VIU. Mi carrera profesional se desarrolló en un centro privado de psicología y psiquiatría hasta incorporarme al equipo de Desarrollo y Lenguaje. A lo largo de estos años he podido participar en varias publicaciones entre las cuales puedo destacar “Rehabilitación de las funciones ejecutivas en TEA” como parte del libro “Investigación y prácticas de la salud. Adaptándose a nuevas realidades” que se presentó en el Congreso Internacional de Intervención e Investigación en Salud.
                            </p>
                        </div>
                    </article>

                    <article className="psicologo row pt-5 px-5">
                        <div className="col-12 col-md-3 d-flex d-md-block justify-content-center">
                            <StaticImage
                                className="psicologo_imagen mb-5 mb-md-0"
                                src="../../assets/avatar/04.png"
                            />
                        </div>
                        <div className="col-12 col-md-9 d-flex-column justify-align-center">
                            <h2 className="psicologo_titulo">Celia Pascal</h2>
                            <h3 className="psicologo_subtitulo">Directora del centro</h3>
                            <h5 className="psicologo_colegiado">Psicóloga General Sanitaria. Nº col: B-1899</h5>
                            <p className="psicologo_txt">Yo tengo la suerte de poder atenderos a todos, llevar las citas, gestionar todas vuestras peticiones y cuadrar agendas de usuarios y terapeutas para que vuestro día a día se haga más llevadero. Atenderé vuestras consultas e emails de contacto.
                            </p>
                        </div>
                    </article>

                </section>
            </main>
        </LayoutBase>
    )
}

export default Nosotros
